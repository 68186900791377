import { plural } from "@lingui/core/macro";
import { takeEvery } from 'typed-redux-saga';
import { addAssetTagsComplete, addAssetTagsReset } from '../actions';
import { uploadTagsSelectors } from '../../../selectors';
import { put, select } from 'redux-saga/effects';
import { createToast } from '@integration-frontends/common/notifications';
import { diContainer } from '@smartsheet/cufflink';
import {
  ContentLibraryStateService,
  contentLibraryStateServiceDIKey,
} from '@smartsheet/content-library-state-service';

export function* handler() {
  const successfulAssetIds: string[] = yield select(uploadTagsSelectors.selectUploadTagsSuccess);
  const errors: string[] = yield select(uploadTagsSelectors.selectUploadTagsErrors);

  if (successfulAssetIds.length > 0) {
    addSuccessfulAssetIdsToStateService(successfulAssetIds);
    yield put(
      createToast({
        id: `upload-tags-success-${Date.now()}`,
        type: 'success',
        toastContent: {
          contentText: plural(successfulAssetIds.length, {
            one: 'Tags added to # file',
            other: 'Tags added to # files',
          }),
        },
      }),
    );
  }

  if (errors.length > 0) {
    yield put(
      createToast({
        id: `upload-tags-errors-${Date.now()}}`,
        type: 'error',
        toastContent: {
          contentText: plural(errors.length, {
            one: 'Error adding tags to # file',
            other: 'Error adding tags to # files',
          }),
        },
      }),
    );
  }

  yield put(addAssetTagsReset());
}

export function* addAssetTagsCompleteEffects() {
  yield takeEvery(addAssetTagsComplete, handler);
}

function addSuccessfulAssetIdsToStateService(assetIds: string[]) {
  const contentLibraryStateService = diContainer.get<ContentLibraryStateService>(
    contentLibraryStateServiceDIKey,
  );

  contentLibraryStateService.setBulkTaggedAssets(assetIds);
}
