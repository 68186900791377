import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { Disclosure } from '@headlessui/react';
import { BFDatePicker, BFLabel, BFStandardRadio } from '@integration-frontends/common/ui';
import {
  AttachmentUploadDate,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import React from 'react';
import { ExpandableSectionIcon, SectionContent, SectionHeader } from '../../common';

export interface UploadDateFilterComponentProps {
  searchParams: SearchParameters;
  onSelect: (date: AttachmentUploadDate) => void;
  onDateRangeChange: (dateStart: string, dateEnd: string) => void;
  onDateStartChange: (dateStart: string) => void;
  onDateEndChange: (dateEnd: string) => void;
}

export function UploadDateFilterComponent({
  searchParams,
  onSelect,
  onDateEndChange,
  onDateStartChange,
}: UploadDateFilterComponentProps) {
  function isChecked(attachmentUploadDate: AttachmentUploadDate): boolean {
    return searchParams?.uploadDateFilter?.uploadDateEnum === attachmentUploadDate || false;
  }

  return (
    <Disclosure defaultOpen={!!searchParams?.uploadDateFilter}>
      {({ open }) => (
        <>
          <SectionHeader>
            <Disclosure.Button as="div" className="flex justify-between">
              <span>
                <Trans>Upload Date</Trans>
              </span>
              <ExpandableSectionIcon expanded={open} />
            </Disclosure.Button>
          </SectionHeader>

          <Disclosure.Panel>
            <SectionContent className="pb-lg">
              <div className="flex items-center">
                <BFStandardRadio
                  checked={!searchParams?.uploadDateFilter}
                  onChange={() => onSelect(null)}
                  className="mr-xs"
                  name="upload-date-filter"
                  value="upload-date-filter"
                  label={t`All`}
                />
              </div>
              <div className="flex items-center">
                <BFStandardRadio
                  value={AttachmentUploadDate.Last30Minutes}
                  checked={isChecked(AttachmentUploadDate.Last30Minutes)}
                  onChange={() => onSelect(AttachmentUploadDate.Last30Minutes)}
                  className="mr-xs"
                  name="upload-date-filter"
                  label={t`Last 30 minutes`}
                />
              </div>
              <div className="flex items-center">
                <BFStandardRadio
                  value={AttachmentUploadDate.Past24Hours}
                  checked={isChecked(AttachmentUploadDate.Past24Hours)}
                  onChange={() => onSelect(AttachmentUploadDate.Past24Hours)}
                  className="mr-xs"
                  name="upload-date-filter"
                  label={t`Past 24 hours`}
                />
              </div>
              <div className="flex items-center">
                <BFStandardRadio
                  value={AttachmentUploadDate.Past7Days}
                  checked={isChecked(AttachmentUploadDate.Past7Days)}
                  onChange={() => onSelect(AttachmentUploadDate.Past7Days)}
                  className="mr-xs"
                  name="upload-date-filter"
                  label={t`Past 7 days`}
                />
              </div>

              <div className="flex items-center">
                <BFStandardRadio
                  value={AttachmentUploadDate.DateRange}
                  checked={isChecked(AttachmentUploadDate.DateRange)}
                  onChange={() => onSelect(AttachmentUploadDate.DateRange)}
                  className="mr-xs"
                  name="upload-date-filter"
                  label={t`Date range`}
                />

                {isChecked(AttachmentUploadDate.DateRange) && (
                  <div className="pt-md gap-xs flex flex-col">
                    <div className="flex items-center justify-between">
                      <BFLabel>
                        <Trans>From</Trans>:
                      </BFLabel>
                      <BFDatePicker
                        value={searchParams?.uploadDateFilter?.dateStart}
                        onChange={(e) => onDateStartChange(e.target.value)}
                      />
                    </div>

                    <div className="flex items-center justify-between">
                      <BFLabel>
                        <Trans>To</Trans>:
                      </BFLabel>
                      <BFDatePicker
                        value={searchParams?.uploadDateFilter?.dateEnd}
                        onChange={(e) => onDateEndChange(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </SectionContent>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
