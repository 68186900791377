import { t } from "@lingui/core/macro";
import { put, takeEvery } from 'typed-redux-saga';
import { showMaxSelectableAssetsToast } from '../actions';
import { createToast } from '@integration-frontends/common/notifications';
import { DEFAULT_SELECTED_ASSETS_LIMIT } from '../reducer';

const handler = function* () {
  yield put(
    createToast({
      id: `select-all-assets-info-${Date.now()}`,
      type: 'info',
      toastContent: {
        contentText: t`You may only select a max of ${DEFAULT_SELECTED_ASSETS_LIMIT} files at a time.`,
      },
    }),
  );
};

export function* showMaxSelectableAssetsToastEffects() {
  yield takeEvery(showMaxSelectableAssetsToast, handler);
}
