import { t } from "@lingui/core/macro";
import { FileUpload, MAX_UPLOAD_COUNT, MAX_UPLOAD_SIZE } from '../../reducer';

export const getViolations = function (fileUploads: FileUpload[]): string[] {
  const sumOfFilesSize = fileUploads.reduce((acc, upload) => acc + upload.file.size, 0);
  const fileCount = fileUploads.length;

  return [
    getFileTypeViolation(fileUploads),
    getFileCountViolation(fileCount, MAX_UPLOAD_COUNT),
    getFileSizeViolation(sumOfFilesSize, MAX_UPLOAD_SIZE),
  ].filter(Boolean);
};

function getFileTypeViolation(fileUploads: FileUpload[]): string {
  const unsupportedFileTypes = ['exe', 'bat', 'html', 'js', 'ini', 'tmp', 'temp', 'bak', 'cache'];

  function hasFileWithExtension(fileUploads, extension) {
    return fileUploads.some((upload) => {
      const fileExtension = upload.file.name.split('.').pop();
      return fileExtension === extension;
    });
  }

  const fileTypeViolations = unsupportedFileTypes.filter((extension) =>
    hasFileWithExtension(fileUploads, extension),
  );

  if (fileTypeViolations.length) {
    return `${t`Uploading files of type`} [.${fileTypeViolations.join(
      ', .',
    )}] ${t`is not supported`}`;
  } else {
    return null;
  }
}

function getFileSizeViolation(sumOfFilesSize: number, maxSize: number): string {
  if (sumOfFilesSize > maxSize) {
    const sumOfAssetsSizeGB = (sumOfFilesSize / (1024 * 1024 * 1024)).toFixed(2);
    return t`Upload is too large (${sumOfAssetsSizeGB}GB). Uploads must be less than 15GB`;
  } else {
    return null;
  }
}

function getFileCountViolation(fileCount: number, maxCount: number): string {
  if (fileCount > maxCount) {
    return t`Too many files selected (${fileCount}). Please select ${MAX_UPLOAD_COUNT} or less files`;
  } else {
    return null;
  }
}
