import { t } from "@lingui/core/macro";
import { IconAdd, IconAddDisabled } from '@integration-frontends/common/ui';
import {
  initiatePlaceAttachment,
  multiSelectAttachmentEnded,
} from '@integration-frontends/integration/core/application';
import noop from 'lodash/noop';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaTypeSupport } from '../../../common/use-media-type-support';
import {
  ATTACHMENT_ACTION_CONTAINER_RESET_MS,
  AttachmentActionContainerBaseProps,
} from '../attachment-actions/attachment-actions-model';

export function PlaceAttachmentActionContainer({
  selectedAttachments,
  render,
  onSuccess = noop,
  isMultiSelect = false,
}: AttachmentActionContainerBaseProps) {
  const PLACE_LABEL = t`Place`;
  const PLACE_SUCCESS_LABEL = t`Placed!`;
  const dispatch = useDispatch();
  const [label, setLabel] = useState<string>(PLACE_LABEL);
  const [success, setSuccess] = useState(false);
  const [attachment] = selectedAttachments;
  const { isSupported } = useMediaTypeSupport();

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        initialize();
        onSuccess();
      }, ATTACHMENT_ACTION_CONTAINER_RESET_MS);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  useEffect(() => {
    initialize();
  }, [selectedAttachments]);

  function initialize() {
    setLabel(PLACE_LABEL);
    setSuccess(false);
  }

  const clicked = useCallback(() => {
    dispatch(
      isMultiSelect
        ? multiSelectAttachmentEnded({ attachments: selectedAttachments })
        : initiatePlaceAttachment({ attachment }),
    );
    setSuccess(true);

    setLabel(PLACE_SUCCESS_LABEL);
    onSuccess(PLACE_SUCCESS_LABEL);
  }, [attachment]);

  const enabled = isSupported(attachment?.mediaType);

  return render({
    id: 'PLACE',
    label,
    onClick: clicked,
    success,
    enabled,
    Icon: enabled ? IconAdd : IconAddDisabled,
    loading: false,
    ariaLabel: PLACE_LABEL,
  });
}
