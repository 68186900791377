import { t } from "@lingui/core/macro";
import { assetTagEntitySelectors } from '@integration-frontends/integration/core/application';
import { Asset } from '@integration-frontends/integration/core/model';
import React from 'react';
import { useSelector } from 'react-redux';
import { PageSection, PageSectionContent } from '../../../common/layout/page';
import { Tags } from '../../common/tags';

export interface TagsProps {
  asset: Asset;
}

export function AssetTags({ asset }: TagsProps) {
  const tags = useSelector(assetTagEntitySelectors.byAssetId(asset.id));

  if (tags.length <= 0) return null;
  return (
    <PageSection title={t`Tags`}>
      <PageSectionContent>
        <Tags tags={tags} />
      </PageSectionContent>
    </PageSection>
  );
}
