import { Trans } from "@lingui/react/macro";
import { Disclosure } from '@headlessui/react';
import {
  BFAnchor,
  BFCaretToggle,
  IconLabel,
  IconLabelsCheckMark,
  IconSize,
} from '@integration-frontends/common/ui';
import {
  containerSelectors,
  labelEntitySelectors,
  searchAssetsSelectors,
} from '@integration-frontends/integration/core/application';
import {
  hasDescendant,
  Label,
  LabelTree,
  positionComparator,
  setLabelFilter,
} from '@integration-frontends/integration/core/model';
import { Body } from '../../../common/layout';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAttachmentSelectorNavigation } from '../../navigation';
import './labels-selector-view.scss';
import { useSearch } from './search/use-search';

interface LabelsSelectorProps {
  labelTrees: LabelTree[];
  selectedLabel?: Label;
  onLabelSelect: (label: Label) => void;
}

function LabelsSelector({ labelTrees, selectedLabel, onLabelSelect }: LabelsSelectorProps) {
  return (
    <div>
      {labelTrees.sort(positionComparator).map((rootNode) => (
        <LabelNode
          key={rootNode.id}
          node={rootNode}
          selectedLabel={selectedLabel}
          onLabelSelect={onLabelSelect}
        />
      ))}
    </div>
  );
}

interface LabelNodeProps {
  node: LabelTree;
  selectedLabel?: Label;
  onLabelSelect: (label: Label) => void;
}

function LabelNode({ node, selectedLabel, onLabelSelect }: LabelNodeProps) {
  const nodeLabelElement = (
    <span
      className="pl-xxs min-w-16 hover:bg-bf-marshmallow flex cursor-pointer items-center"
      style={{ height: 35, minWidth: 200 }}
      onClick={() => onLabelSelect(node)}
      data-testid="label-selector-option"
    >
      <IconLabel className="mr-xs" iconSize={IconSize.Medium} />
      {node.name}
      {selectedLabel?.id === node?.id && (
        <IconLabelsCheckMark className="pl-xs" iconSize={IconSize.Large} />
      )}
    </span>
  );

  return (
    <>
      {node.children?.length > 0 ? (
        <Disclosure defaultOpen={hasDescendant(selectedLabel, node)}>
          {({ open }) => (
            <>
              <div className="mb-xxs flex">
                <Disclosure.Button as="span">
                  <BFCaretToggle open={open} />
                </Disclosure.Button>
                {nodeLabelElement}
              </div>
              <Disclosure.Panel as="div" className="pl-xl">
                {node.children.sort(positionComparator).map((childNode) => (
                  <LabelNode
                    key={childNode.id}
                    node={childNode}
                    selectedLabel={selectedLabel}
                    onLabelSelect={onLabelSelect}
                  />
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ) : (
        <div className="pl-xxl mb-xxs flex items-center">{nodeLabelElement}</div>
      )}
    </>
  );
}

export interface LabelsSelectorViewProps {
  onClose: () => void;
}

export function LabelsSelectorView({ onClose }: LabelsSelectorViewProps) {
  const { search } = useSearch();
  const navigation = useAttachmentSelectorNavigation();
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, navigation.containerId),
  );
  const labelTrees = useSelector(labelEntitySelectors.labelTreesByContainer(selectedContainer));
  const searchParams = useSelector(searchAssetsSelectors.searchParams);

  const onLabelSelected = useCallback((label: Label) => {
    search(setLabelFilter(label, searchParams));
    onClose();
  }, []);

  return (
    <div className="labels-selector-view">
      <div className="px-lg flex  items-center justify-between ">
        <span className="labels-title font-bold">
          <Trans>Labels</Trans>
        </span>
        <BFAnchor onClick={onClose}>
          <Trans>Hide labels</Trans>
        </BFAnchor>
      </div>
      <Body>
        <h4 className="labels-selector-container-name">{selectedContainer?.name}</h4>{' '}
        <LabelsSelector
          labelTrees={labelTrees}
          selectedLabel={searchParams?.labelFilter}
          onLabelSelect={onLabelSelected}
        />
      </Body>
    </div>
  );
}
