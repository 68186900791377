import { t } from "@lingui/core/macro";
import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import { AssetsListPagination } from '@integration-frontends/integration/core/application/common/assets-list/assets-list-state/assets-list-pagination';
import { Section } from '@integration-frontends/integration/core/model';
import React from 'react';
import {
  INTEGRATION_UI_STYLING_OPTIONS_TOKEN,
  IntegrationUiStylingOptions,
} from '../../../styling-options';
import { CurrentPageDisplay } from '../current-page-display';
import './assets-list-header.scss';

export const ASSETS_LIST_HEADER_COMPONENT_TOKEN = 'ASSETS_LIST_HEADER_COMPONENT';

export interface AssetsListHeaderContainerProps {
  title: string;
  pagination: AssetsListPagination;
  section: Section;
}

export interface AssetsListHeaderComponentProps {
  title: string;
  pagination: AssetsListPagination;
}

export function DefaultAssetsListHeaderComponent({
  title,
  pagination,
}: AssetsListHeaderComponentProps) {
  const stylingOptions = DI_CONTAINER.get<IntegrationUiStylingOptions>(
    INTEGRATION_UI_STYLING_OPTIONS_TOKEN,
  );

  const allAssetsHeader = t`All Assets`;

  return (
    <div
      className="assets-list-title flex justify-between"
      style={{ backgroundColor: stylingOptions.backgroundColor }}
    >
      <h3 className="text-primary">{title || allAssetsHeader}</h3>
      <CurrentPageDisplay pagination={pagination} />
    </div>
  );
}

export function AssetsListHeaderContainer(props: AssetsListHeaderContainerProps) {
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const HeaderComponent =
    componentResolver.getComponent(ASSETS_LIST_HEADER_COMPONENT_TOKEN) ||
    DefaultAssetsListHeaderComponent;

  return <HeaderComponent {...props} />;
}
