import {
  AttachmentFieldParameter,
  FieldParameter,
  getResponseDataOrDefault,
  getResponseListDataOrDefault,
  Relationship,
  SortField as ApiSortField,
  AttachmentOptions,
  SortDirection,
  ApiListDataResponse,
  AttachmentDto,
  getResponseMeta,
} from '@integration-frontends/common/brandfolder-api';
import {
  Attachment,
  AttachmentListResultSet,
  Container,
  IAttachmentRepo,
  ListOptions,
  PagedResults,
  ResourceBaseSortableProperty,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { map } from 'ramda';
import { AttachmentWithDto, buildSearchQuery, mapAttachmentDto } from './model';
import { RepoBase } from './repo-base';

@injectable()
export class AttachmentRepo extends RepoBase implements IAttachmentRepo {
  constructor(private extraParameters: AttachmentFieldParameter[] = []) {
    super();
  }

  getAttachment = async (attachmentId: string): Promise<AttachmentWithDto> => {
    return this.brandfolderApi
      .fetchAttachment(await this.getApiKey(), attachmentId, {
        include: [Relationship.ASSET],
        fields: this.getFieldParameters(),
      })
      .then(getResponseDataOrDefault)
      .then(mapAttachmentDto);
  };

  listAssetAttachments = async (assetId: string): Promise<AttachmentWithDto[]> => {
    return this.brandfolderApi
      .listAssetAttachments(await this.getApiKey(), assetId, {
        include: [Relationship.ASSET],
        fields: this.getFieldParameters(),
      })
      .then(getResponseListDataOrDefault)
      .then(map(mapAttachmentDto));
  };

  listContainerAttachments = async (
    container: Container,
    options: ListOptions,
  ): Promise<PagedResults<AttachmentListResultSet>> => {
    return container.type === ResourceType.BRANDFOLDER
      ? this._listBrandfolderAttachments(container.id, options)
      : this._listCollectionAttachments(container.id, options);
  };

  // NOTE: only exists for file library (Collection) as of Sept 23, 2024
  getAssetAttachment = async (assetId: string): Promise<AttachmentWithDto> => {
    return this.brandfolderApi
    .getAssetAttachment(await this.getApiKey(), assetId)
    .then(getResponseDataOrDefault)
    .then(mapAttachmentDto);
  };

  private _listBrandfolderAttachments = async (
    brandfolderId: string,
    options?: ListOptions,
  ): Promise<PagedResults<AttachmentListResultSet>> => {
    return this.brandfolderApi
      .listCollectionAttachments(await this.getApiKey(), brandfolderId, {
        ...this._generateDefaultAtachmentListOptions(options),
        fields: this.getFieldParameters(),
      })
      .then((res) => this._mapAttachmentsListResponse(res, options));
  };

  private _listCollectionAttachments = async (
    collectionId: string,
    options?: ListOptions,
  ): Promise<PagedResults<AttachmentListResultSet>> => {
    return this.brandfolderApi
      .listCollectionAttachments(await this.getApiKey(), collectionId, {
        ...this._generateDefaultAtachmentListOptions(options),
        fields: this.getFieldParameters(),
      })
      .then((res) => this._mapAttachmentsListResponse(res, options));
  };

  private getFieldParameters(): AttachmentFieldParameter[] {
    return [
      FieldParameter.CdnUrl,
      FieldParameter.CreatedAt,
      FieldParameter.UpdatedAt,
      FieldParameter.IsProcessing,
      ...this.extraParameters,
    ];
  }

  private _generateDefaultAtachmentListOptions(options: ListOptions): AttachmentOptions {
    const sortFieldMap = {
      [ResourceBaseSortableProperty.Position]: ApiSortField.Position,
      [ResourceBaseSortableProperty.CreatedAt]: ApiSortField.CreatedAt,
      [ResourceBaseSortableProperty.Name]: ApiSortField.Name,
      [ResourceBaseSortableProperty.UpdatedAt]: ApiSortField.UpdatedAt,
      [ResourceBaseSortableProperty.Popularity]: ApiSortField.Score,
    };

    return {
      ...this.getFieldParameters(),
      search: buildSearchQuery(options?.searchParams),
      per: options?.pagination?.perPage,
      page: options?.pagination?.page,
      sort: {
        direction: (options?.sort?.direction === 'DESC' && SortDirection.DESC) || SortDirection.ASC,
        field: sortFieldMap[options?.sort?.field] || ApiSortField.Position,
      },
    };
  }

  private _mapAttachmentsListResponse(
    res: ApiListDataResponse<AttachmentDto>,
    options?: ListOptions,
  ): PagedResults<AttachmentListResultSet> {
    return {
      data: {
        attachments: getResponseListDataOrDefault(res).map((attachment) =>
          mapAttachmentDto(attachment),
        ),
      },
      currentPage: getResponseMeta(res).current_page,
      totalCount: getResponseMeta(res).total_count,
      totalPages: getResponseMeta(res).total_pages,
      nextPage: getResponseMeta(res).next_page,
      perPage: options?.pagination?.perPage || 1,
    };
  }
}
