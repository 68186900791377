import { t } from "@lingui/core/macro";
import {
  ContainerCustomField,
  ContainerCustomFieldValue,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import React from 'react';
import { BFLabel, BFSelect } from '@integration-frontends/common/ui';

export interface PrioritizedCustomFieldsFiltersSelectComponentProps {
  customField: ContainerCustomField;
  customFieldValues: ContainerCustomFieldValue[];
  searchParams: SearchParameters;
  onChange: (field: ContainerCustomField, value: string) => void;
}

export const PrioritizedCustomFieldsFiltersSelectComponent = ({
  customField,
  customFieldValues,
  searchParams,
  onChange,
}: PrioritizedCustomFieldsFiltersSelectComponentProps) => {
  return (
    <div key={customField.id} className="flex flex-col">
      <BFLabel htmlFor={customField.name}>{customField.name}</BFLabel>
      <BFSelect
        id={customField.name}
        required
        placeholder={t`Select`}
        data-testid="prioritized-custom-fields-filter-select"
        options={customFieldValues.map((customFieldValue) => ({
          label: customFieldValue.value,
          value: customFieldValue.value,
        }))}
        value={searchParams?.customFieldFilters?.[customField.id]?.value || ''}
        onOptionChange={(option) => onChange(customField, option?.value as string)}
        className="text-primary text-base"
      />
    </div>
  );
};
