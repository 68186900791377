import { t } from "@lingui/core/macro";
import { contentLibraryUploadAssetsSelectors } from '../../../../selectors';
import { call, put, select, take, takeEvery } from 'typed-redux-saga';
import {
  createUploadErrorToast,
  handleUploadErrorToast,
  updateUploadErrorToast,
  uploadErrorToastClosed,
} from '../../actions';
import { UploadErrorToast } from '../../reducer';
import { eventChannel } from 'redux-saga';

export function* handleUploadErrorToastEffect() {
  yield takeEvery(handleUploadErrorToast, handler);
}

const handler = function* () {
  const uploadErrorToast: UploadErrorToast = yield select(
    contentLibraryUploadAssetsSelectors.uploadErrorToast,
  );

  let uploadErrorMessage;
  let toastId;

  if (uploadErrorToast) {
    const numUploadErrors = uploadErrorToast.numUploadErrors + 1;
    toastId = uploadErrorToast.id;
    uploadErrorMessage = t`Unable to add ${numUploadErrors} files to the file library`;

    yield put(
      updateUploadErrorToast({
        toastId,
        numUploadErrors: numUploadErrors,
        uploadErrorMessage,
      }),
    );
  } else {
    toastId = crypto.randomUUID();
    uploadErrorMessage = t`Unable to add 1 file to the file library`;
    yield put(createUploadErrorToast({ toastId: toastId, uploadErrorMessage }));
  }

  // Watch for toast disappearance
  const channel = yield call(createToastObserverChannel, toastId, uploadErrorMessage);
  try {
    yield take(channel);
    yield put(uploadErrorToastClosed());
  } finally {
    channel.close();
  }
};

function createToastObserverChannel(toastId: string, uploadErrorMessage: string) {
  return eventChannel((emitter) => {
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList') {
          const removedNodes = Array.from(mutation.removedNodes);
          if (
            removedNodes.some((node) => {
              const element = node as Element;
              return element.textContent && element.textContent.includes(uploadErrorMessage);
            })
          ) {
            emitter(true);
            observer.disconnect();
          }
        }
      }
    });

    const toastContainer = document.body;
    if (toastContainer) {
      observer.observe(toastContainer, { childList: true, subtree: true });
    } else {
      emitter(true);
    }

    return () => {
      observer.disconnect();
    };
  });
}
