import { takeEvery } from 'redux-saga/effects';
import { put, select } from 'typed-redux-saga';
import { searchAssetsSelectors } from '../../../selectors';
import { baseClearSearch, baseSearch, search } from '../actions';

function* handler(action: ReturnType<typeof baseClearSearch>) {
  // maintain sort options when clearing search
  const sortOptions = yield select(searchAssetsSelectors.sortOptions);
  const searchParams = yield select(searchAssetsSelectors.searchParams);

  if (action.payload.executeSearch && action.payload.onlyFilters) {
    yield put(baseSearch({ searchParams: {query: searchParams.query}, sortOptions}));
  } else if (action.payload.executeSearch) {
    yield put(baseSearch({ searchParams: null, sortOptions }));
  }
}

export function* baseClearSearchEffects() {
  yield takeEvery(baseClearSearch, handler);
}
