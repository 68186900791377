import { plural } from '@lingui/core/macro';
/* eslint-disable @nx/enforce-module-boundaries */
import {
  Banner,
  BannerType,
  createBanner,
  openBanner,
  selectAllBanners,
  updateBanner,
} from '@integration-frontends/common/notifications';
import { put, select } from 'typed-redux-saga';
import { contentLibraryUploadAssetsSelectors } from '@integration-frontends/integration/core/application/selectors';

export interface HandleUploadProgressBannerProps {
  numAssetsToAddToProgress?: number;
}

export function* handleUploadProgressBanner({
  numAssetsToAddToProgress = 0,
}: HandleUploadProgressBannerProps = {}) {
  const banners: Banner[] = yield select(selectAllBanners);
  const uploadSequenceId = yield select(contentLibraryUploadAssetsSelectors.uploadSequenceId);
  const uploadHasProgressBanner = banners.find(
    (banner) => banner.id === `upload-${uploadSequenceId}`,
  );
  const currentAssetUploadIndex: number = yield select(
    contentLibraryUploadAssetsSelectors.currentAssetUploadIndex,
  );
  const currentAssetsInUpload: string[] = yield select(
    contentLibraryUploadAssetsSelectors.currentAssetsInUpload,
  );
  const currentAssetsInUploadLength = currentAssetsInUpload.length + numAssetsToAddToProgress;

  const bannerMessage = plural(currentAssetsInUploadLength, {
    one: `Uploading ${currentAssetUploadIndex + 1} of # file...`,
    other: `Uploading ${currentAssetUploadIndex + 1} of # files...`,
  });

  if (!uploadHasProgressBanner) {
    yield put(
      createBanner({
        id: `upload-${uploadSequenceId}`,
        type: BannerType.Info,
        bannerContent: {
          contentText: bannerMessage,
          displayDismissButton: false,
        },
      }),
    );
  } else {
    yield put(openBanner({ id: `upload-${uploadSequenceId}` }));
    yield put(
      updateBanner({
        id: `upload-${uploadSequenceId}`,
        bannerContent: {
          contentText: bannerMessage,
        },
      }),
    );
  }
}
