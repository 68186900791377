import { t } from "@lingui/core/macro";
import {
  BFButton,
  ButtonSize,
  ButtonType,
  IconCaretDown,
  IconChevronDown,
  IconChevronRight,
  IconSize,
} from '@integration-frontends/common/ui';
import React, { HTMLAttributes } from 'react';

export interface CollectionsToggleProps {
  collectionCount: number;
  open: boolean;
  primary?: boolean;
}

export function CollectionsToggle({
  collectionCount,
  open,
  primary,
  ...props
}: CollectionsToggleProps & HTMLAttributes<HTMLDivElement>) {
  const COLLECTIONS_KEY = t`Collections`;
  const COLLECTION_KEY = t`Collection`;
  return (
    <div className="flex cursor-pointer items-center whitespace-nowrap" {...props}>
      {primary ? (
        <BFButton
          buttonType={ButtonType.Secondary}
          dataTestId="collections-selector-toggle"
          size={ButtonSize.Small}
          className="flex items-center justify-between"
          iconAfter={<IconCaretDown iconSize={IconSize.Small} />}
        >
          {collectionCount} {collectionCount > 1 ? COLLECTIONS_KEY : COLLECTION_KEY}
        </BFButton>
      ) : (
        <>
          {collectionCount} {collectionCount > 1 ? COLLECTIONS_KEY : COLLECTION_KEY}
          {open ? (
            <IconChevronDown className="pl-xxs" iconSize={IconSize.Large} />
          ) : (
            <IconChevronRight className="pl-xxs" iconSize={IconSize.Large} />
          )}
        </>
      )}
    </div>
  );
}
