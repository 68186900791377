import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { Popover } from '@headlessui/react';
import {
  assetEntitySelectors,
  containerSelectors,
  IUploadAssetsOptions,
  uploadAssetsInit,
  UPLOAD_ASSETS_OPTIONS_TOKEN,
} from '@integration-frontends/integration/core/application';
import { logoutThunk } from '@integration-frontends/common/auth/core/application';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconHamburgerMenu,
  IconHome,
  IconLogout,
  IconOpenInBrandfolder,
  IconUploadAssets,
} from '@integration-frontends/common/ui';
import { usePopper } from 'react-popper';
import { Link } from 'react-router-dom';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  IBrandfolderWebsiteService,
} from '@integration-frontends/integration/core/model';

interface PanelProps {
  assetId: string;
  containerId: string;
}

function Panel({ assetId, containerId }: PanelProps) {
  const bfWebsiteService: IBrandfolderWebsiteService = DI_CONTAINER.get(
    BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  );
  const { showUploadAssetsButton }: IUploadAssetsOptions = DI_CONTAINER.get(
    UPLOAD_ASSETS_OPTIONS_TOKEN,
  );
  const dispatch = useDispatch();
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const selectedAsset = useSelector((state) => assetEntitySelectors.selectById(state, assetId));

  const onLogout = () => {
    dispatch(logoutThunk() as any);
  };

  const uploadInit = () => {
    dispatch(uploadAssetsInit);
  };

  return (
    <div className="menu-card-popover">
      <Link
        to="/select-attachment"
        className="text-primary menu-card-item text-base"
        data-cy="home-button"
      >
        <IconHome className="mr-sm" />
        <Trans>Home</Trans>
      </Link>
      {(selectedAsset || selectedContainer) && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            selectedAsset
              ? bfWebsiteService.getAssetPageUrl(selectedContainer, selectedAsset)
              : bfWebsiteService.getContainerUrl(selectedContainer)
          }
          className="text-primary menu-card-item text-base"
        >
          <IconOpenInBrandfolder className="mr-sm text-primary" /> <Trans>Open in Brandfolder</Trans>
        </a>
      )}
      {showUploadAssetsButton && (
        <Link
          data-testid="upload-menu-item"
          to="/upload"
          className="text-primary menu-card-item text-base"
          onClick={uploadInit}
        >
          <IconUploadAssets className="mr-sm" /> {t`Upload assets`}
        </Link>
      )}
      <button data-cy="logout" className="text-primary menu-card-item text-base" onClick={onLogout}>
        <IconLogout className="mr-sm" /> {t`Logout`}
      </button>
    </div>
  );
}

export interface MenuPopoutProps {
  assetId: string;
  containerId: string;
}

export function MenuPopout({ assetId, containerId }: MenuPopoutProps) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
    strategy: 'absolute',
  });

  return (
    <Popover data-cy="popover-menu" data-testid="popover-menu" style={{ zIndex: 1000 }}>
      <Popover.Button as="button" ref={setReferenceElement}>
        <span className="flex text-base">
          <IconHamburgerMenu className="mr-xxs" /> Menu
        </span>
      </Popover.Button>
      <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <Panel assetId={assetId} containerId={containerId} />
      </Popover.Panel>
    </Popover>
  );
}
