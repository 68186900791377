import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { BFLabel, BFSelect, BFSelectOption } from '@integration-frontends/common/ui';
import {
  AssetStatus,
  SearchParameters,
  setAssetStatusFilter,
} from '@integration-frontends/integration/core/model';
import noop from 'lodash/noop';
import { isNil } from 'ramda';
import React from 'react';
import { SectionContent } from '../common';

export interface AssetStatusFilterProps {
  searchParams: SearchParameters;
  onChange?: (searchParams: SearchParameters) => void;
}

export function AssetStatusFilter({ searchParams, onChange = noop }: AssetStatusFilterProps) {
  function changed(option?: BFSelectOption) {
    onChange(setAssetStatusFilter(searchParams, (option?.value as AssetStatus) || null));
  }

  return (
    <SectionContent className="py-lg">
      <div className="gap-lg flex items-center justify-between">
        <BFLabel id={`asset-status-label`} className="text-primary whitespace-nowrap">
          <Trans>Asset status</Trans>
        </BFLabel>
        <BFSelect
          aria-labelledby={`asset-status-label`}
          required
          placeholder={t`Select status`}
          options={[
            {
              label: t`Approved`,
              value: AssetStatus.Approved,
            },
            {
              label: t`Unapproved`,
              value: AssetStatus.Unapproved,
            },
            {
              label: t`Draft`,
              value: AssetStatus.Draft,
            },
            {
              label: t`Expired`,
              value: AssetStatus.Expired,
            },
          ]}
          className="text-primary text-base"
          style={{ flex: '1 1 240px' }}
          value={isNil(searchParams?.assetStatusFilter) ? '' : searchParams.assetStatusFilter}
          onOptionChange={changed}
        />
      </div>
    </SectionContent>
  );
}
