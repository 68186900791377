import {
  BRANDFOLDER_REPO_TOKEN,
  COLLECTION_REPO_TOKEN,
  Container,
  ContainerCustomField,
  ContainerFileTypeAggregates,
  ContainerTag,
  IBrandfolderRepo,
  ICollectionRepo,
  IContainerRepo,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { inject, injectable } from 'inversify';
import { map } from 'ramda';
import { RepoBase } from './repo-base';

@injectable()
export class ContainerRepo extends RepoBase implements IContainerRepo {
  @inject(BRANDFOLDER_REPO_TOKEN)
  private brandfolderRepo: IBrandfolderRepo;
  @inject(COLLECTION_REPO_TOKEN)
  private collectionRepo: ICollectionRepo;

  getContainer = async (containerId: string) => {
    const [brandfolder, collection] = await Promise.all([
      this.brandfolderRepo.getBrandfolder(containerId),
      this.collectionRepo.getCollection(containerId),
    ]);

    return brandfolder || collection;
  };

  getContainerCustomFields = async (container: Container): Promise<ContainerCustomField[]> => {
    if (!container) return [];

    return container.type === ResourceType.BRANDFOLDER
      ? this.brandfolderRepo
          .getBrandfolderCustomFields(container.id)
          .then(map(mapBrandfolderChildEntity))
      : this.collectionRepo
          .getCollectionCustomFields(container.id)
          .then(map(mapCollectionChildEntity));
  };

  getContainerCustomFieldValues = async (container: Container, customFieldKey: string): Promise<any[]> => {
    if (!container) return [];

    return container.type === ResourceType.BRANDFOLDER
      ? this.brandfolderRepo
          .getBrandfolderCustomFieldValues(container.id, customFieldKey)
      : this.collectionRepo
          .getCollectionCustomFieldValues(container.id, customFieldKey);
  };

  getContainerFileTypeAggregates = async (
    container: Container,
  ): Promise<ContainerFileTypeAggregates[]> => {
    if (!container) return [];
    return container.type === ResourceType.BRANDFOLDER
      ? this.brandfolderRepo
          .getBrandfolderFileTypeAggregates(container.id)
          .then(map(mapBrandfolderChildEntity))
      : this.collectionRepo
          .getCollectionFileTypeAggregates(container.id)
          .then(map(mapCollectionChildEntity));
  };

  getContainerTags = async (container: Container): Promise<ContainerTag[]> => {
    if (!container) return [];
    return container.type === ResourceType.BRANDFOLDER
      ? this.brandfolderRepo.getBrandfolderTags(container.id).then(map(mapBrandfolderChildEntity))
      : this.collectionRepo.getCollectionTags(container.id).then(map(mapCollectionChildEntity));
  };

  getContainerFiletypeAggregations = async (
    container: Container,
  ): Promise<ContainerFileTypeAggregates[]> => {
    // NOTE: only exists for file library (Collection) as of Sept 5, 2024
    if (!container || container.type === ResourceType.BRANDFOLDER) return [];
    return this.collectionRepo.getCollectionFiletypeAggregations(container.id).then(map(mapCollectionChildEntity));
  };

  getContainerTagAggregations = async (container: Container): Promise<ContainerTag[]> => {
    // NOTE: only exists for file library (Collection) as of Sept 5, 2024
    if (!container || container.type === ResourceType.BRANDFOLDER) return [];
    return this.collectionRepo.getCollectionTagAggregations(container.id).then(map(mapCollectionChildEntity));
  };
}

function mapBrandfolderChildEntity<T extends { brandfolderId: string }>(
  entity: T,
): T & { containerId: string } {
  return {
    ...entity,
    containerId: entity.brandfolderId,
  };
}

function mapCollectionChildEntity<T extends { collectionId: string }>(
  entity: T,
): T & { containerId: string } {
  return {
    ...entity,
    containerId: entity.collectionId,
  };
}
