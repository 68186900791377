/* eslint-disable @nx/enforce-module-boundaries */
import { call, put, select, takeEvery } from 'typed-redux-saga';
import {
  containerTagsActions,
  SearchAssetsSelectors,
  searchTags,
} from '@integration-frontends/integration/core/application';
import {
  COLLECTION_REPO_TOKEN,
  ICollectionRepo,
} from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';

function* handler(selectors: SearchAssetsSelectors, action: ReturnType<typeof searchTags>) {
  const collectionRepo: ICollectionRepo = DI_CONTAINER.get(COLLECTION_REPO_TOKEN);
  const container = yield select(selectors.container);
  const tags = yield call(collectionRepo.searchTags, container.id, action.payload);

  yield put(containerTagsActions.containerTagsReceived(tags));
}

export function* filterTagsEffects(selectors: SearchAssetsSelectors) {
  yield takeEvery(searchTags, handler, selectors);
}
