import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { BFSearchInput, BFStandardCheckbox, InputSize } from '@integration-frontends/common/ui';
import {
  ContainerFileTypeAggregates,
  hasFileTypeFilter,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import React, { useState } from 'react';
import {
  CheckboxListContainer,
  CheckboxListItemContainer,
  SectionContent,
  SectionHeader,
} from '../../common';
import { orderBy } from 'lodash';
import styled from '@emotion/styled';

export interface TopFileTypesFiltersComponentProps {
  fileTypeAggregates: ContainerFileTypeAggregates[];
  searchParams: SearchParameters;
  fileTypeToggle: (fileType: string) => void;
}

export function TopFileTypesFiltersComponent({
  fileTypeAggregates,
  searchParams,
  fileTypeToggle,
}: TopFileTypesFiltersComponentProps) {
  const [search, setSearch] = useState('');

  const sortedFileTypeAggregates = orderBy(fileTypeAggregates, ['name']);

  return (
    <fieldset>
      <SectionHeader>
        <Title className="text-primary">
          <Trans>Top File Types</Trans>
        </Title>
      </SectionHeader>

      <SectionContent className="pb-lg">
        <CheckboxListContainer className="mb-xl">
          {sortedFileTypeAggregates
            .filter(
              (fileType) =>
                search === '' || fileType.name.toLowerCase().includes(search.toLowerCase()),
            )
            .slice(0, 10)
            .map((fileType) => (
              <CheckboxListItemContainer key={fileType.name}>
                <div
                  className="flex cursor-pointer justify-between"
                  onClick={() => fileTypeToggle(fileType.name)}
                >
                  <BFStandardCheckbox
                    value={fileType.name}
                    checked={hasFileTypeFilter(searchParams, fileType.name)}
                    style={{ marginRight: 10 }}
                    label={fileType.name?.toLocaleUpperCase()}
                    readOnly
                  />
                </div>
              </CheckboxListItemContainer>
            ))}
        </CheckboxListContainer>

        <BFSearchInput
          className="w-full"
          size={InputSize.Small}
          placeholder={t`Search File Types`}
          onChange={(e) => setSearch(e.target.value)}
        />
      </SectionContent>
    </fieldset>
  );
}

const Title = styled.legend`
  display: flex;
  justify-content: start;
`;
