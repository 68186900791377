import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { Disclosure } from '@headlessui/react';
import {
  BFStandardCheckbox,
  IconOrientationHorizontal,
  IconOrientationPanoramic,
  IconOrientationSquare,
  IconOrientationVertical,
} from '@integration-frontends/common/ui';
import {
  AttachmentOrientation,
  hasOrientationFilter,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import React from 'react';
import { ExpandableSectionIcon, SectionContent, SectionHeader } from '../../common';

export interface OrientationFiltersComponentProps {
  searchParams: SearchParameters;
  onChange: (orientation: AttachmentOrientation, checked: boolean) => void;
}

export function OrientationFiltersComponent({
  searchParams,
  onChange,
}: OrientationFiltersComponentProps) {
  function isChecked(orientation: AttachmentOrientation): boolean {
    return hasOrientationFilter(searchParams, orientation);
  }

  return (
    <Disclosure defaultOpen={searchParams?.orientationFilters?.length > 0}>
      {({ open }) => {
        return (
          <>
            <SectionHeader>
              <Disclosure.Button as="div" className="flex justify-between">
                <span>
                  <Trans>Orientation</Trans>
                </span>
                <ExpandableSectionIcon expanded={open} />
              </Disclosure.Button>
            </SectionHeader>

            <Disclosure.Panel>
              <SectionContent className="pb-lg">
                <div className="gap-md flex flex-col">
                  <div className="flex justify-between">
                    <BFStandardCheckbox
                      checked={isChecked(AttachmentOrientation.Horizontal)}
                      onChange={(e) => onChange(AttachmentOrientation.Horizontal, e.target.checked)}
                      className="mr-sm"
                      label={t`Horizontal`}
                    />

                    <IconOrientationHorizontal />
                  </div>

                  <div className="flex justify-between">
                    <BFStandardCheckbox
                      checked={isChecked(AttachmentOrientation.Vertical)}
                      onChange={(e) => onChange(AttachmentOrientation.Vertical, e.target.checked)}
                      className="mr-sm"
                      label={t`Vertical`}
                    />

                    <IconOrientationVertical />
                  </div>

                  <div className="flex justify-between">
                    <BFStandardCheckbox
                      checked={isChecked(AttachmentOrientation.Square)}
                      onChange={(e) => onChange(AttachmentOrientation.Square, e.target.checked)}
                      className="mr-sm"
                      label={t`Square`}
                    />

                    <IconOrientationSquare />
                  </div>

                  <div className="flex justify-between">
                    <BFStandardCheckbox
                      checked={isChecked(AttachmentOrientation.Panoramic)}
                      onChange={(e) => onChange(AttachmentOrientation.Panoramic, e.target.checked)}
                      className="mr-sm"
                      label={t`Panoramic`}
                    />

                    <IconOrientationPanoramic />
                  </div>
                </div>
              </SectionContent>
            </Disclosure.Panel>
          </>
        );
      }}
    </Disclosure>
  );
}
