import { plural, t } from "@lingui/core/macro";
import { takeEvery } from 'redux-saga/effects';
import {
  Asset,
  COLLECTION_REPO_TOKEN,
  ICollectionRepo,
} from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { put, call, select, delay } from 'typed-redux-saga';
import {
  bulkRemoveAssetsFromCollectionsInit,
  bulkRemoveAssetsFromCollectionsSuccess,
  deleteError,
} from '../actions';
import { createToast } from '@integration-frontends/common/notifications';
import { contentLibraryUploadAssetsSelectors } from '../../../selectors';
import { removeFromPinnedUploads } from '../../../content-library-upload-assets';
import { CONTENT_LIBRARY_FILE_LIMIT } from '../../../common';

/**
 * CONTENT_LIBRARY_FILE_LIMIT Matches https://github.com/brandfolder/boulder/blob/df20e76e471bcfb851bcfb30cbb621c89e761dc3/app/controllers/api/v4/private/smartsheet/api_controller.rb#L28
 */

interface RemoveAssetsFromCollectionsResponse {
  result: 'accepted' | 'error';
}

function* handler(action: ReturnType<typeof bulkRemoveAssetsFromCollectionsInit>) {
  const collectionRepo: ICollectionRepo = DI_CONTAINER.get(COLLECTION_REPO_TOKEN);
  const { assetIds, collectionId } = action.payload;
  const pinnedUploads: Asset[] = yield select(contentLibraryUploadAssetsSelectors.pinnedUploads);
  const pinnedUploadsBeingDeleted = pinnedUploads.filter((asset) => assetIds.includes(asset.id));

  yield put(
    removeFromPinnedUploads({
      assetIds: pinnedUploadsBeingDeleted.map((asset) => asset.id),
    }),
  );

  try {
    const response: RemoveAssetsFromCollectionsResponse = yield call(
      collectionRepo.removeAssetsFromCollections,
      collectionId,
      assetIds,
    );

    if (response.result === 'accepted') {
      /**
      * NOTE: Bulk deleting assets is an async process. The delay here is meant to prevent the user from using the UI
      * in a manner that would show assets that have not been deleted yet, like immediately using search after delete.
      * Steve did BE performance work here: https://github.com/brandfolder/boulder/pull/19381, but this is a FE safeguard.
      */
      const _delay = Math.max(300, Math.min(assetIds.length, CONTENT_LIBRARY_FILE_LIMIT));
      yield delay(_delay);

      yield put(
        createToast({
          id: `delete-asset-success-${Date.now()}`,
          type: 'success',
          toastContent: {
            contentText: plural(assetIds.length, {
              one: '# file deleted',
              other: '# files deleted',
            }),
          },
        }),
      );
      yield put(bulkRemoveAssetsFromCollectionsSuccess({ assetIds }));
    }
  } catch (error) {
    yield put(
      createToast({
        id: `delete-asset-error-${Date.now()}`,
        type: 'error',
        toastContent: { contentText: t`Error deleting files` },
      }),
    );
    yield put(deleteError());
  }
}

export function* bulkRemoveAssetsFromCollectionsEffects() {
  yield takeEvery(bulkRemoveAssetsFromCollectionsInit, handler);
}
