import { Trans } from "@lingui/react/macro";
import { BFInput, BFLabel } from '@integration-frontends/common/ui';
import { LabelOptions, setLabel } from '@integration-frontends/integration/core/application';
import React from 'react';

export interface LabelProps {
  options: LabelOptions;
  onChange: (options: LabelOptions) => void;
}

export function Label({ options, onChange }: LabelProps) {
  return (
    <div className="flex w-full flex-col">
      <BFLabel>
        <Trans>Image Label</Trans>
      </BFLabel>
      <BFInput
        type="text"
        value={options.label}
        onChange={(e) => onChange(setLabel(e.target.value, options))}
      />
    </div>
  );
}
