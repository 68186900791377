import { Trans } from "@lingui/react/macro";
import { BFButton } from '@integration-frontends/common/ui';
import { deselectAllAttachments } from '@integration-frontends/integration/core/application';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAttachmentSelectorNavigation } from '../../navigation';

export function MultiSelectModal({ prompt, subText, toggleModal }) {
  const dispatch = useDispatch();
  const navigation = useAttachmentSelectorNavigation();

  const stayOnPage = () => {
    toggleModal(false);
  };

  const exitBrandfolder = () => {
    dispatch(deselectAllAttachments());
    navigation.goToContainerSelector();
  };

  return (
    <section className="bg-bf-black-50-opacity z-100 fixed top-0 left-0 flex h-screen w-screen flex-col items-center justify-center overflow-hidden">
      <section className="bg-bf-white flex h-1/3 w-6/12 flex-col items-center justify-center rounded-md">
        <p
          style={{
            marginTop: '15px',
            marginRight: '15px',
            marginBottom: '15px',
            marginLeft: '15px',
          }}
          className="text-xl"
        >
          <Trans>Are you sure you want to exit this Brandfolder?</Trans>
        </p>
        <p className="text-bf-darker-gray cursor-pointer text-base">{prompt}</p>
        <p className="text-bf-darker-gray cursor-pointer text-base">{subText}</p>
        <div className="align-center flex flex-row justify-center">
          <BFButton
            style={{
              backgroundColor: 'white',
              color: '#4462C9',
              borderColor: '#E5E5E5',
              borderWidth: '1px',
              marginTop: '15px',
              marginRight: '15px',
              marginBottom: '15px',
              marginLeft: '15px',
            }}
            onClick={stayOnPage}
          >
            <Trans>No, remain</Trans>
          </BFButton>

          <BFButton
            style={{
              marginTop: '15px',
              marginRight: '15px',
              marginBottom: '15px',
              marginLeft: '15px',
            }}
            onClick={exitBrandfolder}
          >
            <Trans>Yes, leave</Trans>
          </BFButton>
        </div>
      </section>
    </section>
  );
}
