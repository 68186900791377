import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { FlexSpacer } from '../../../common/layout/flex-spacer';
import React, { useEffect, useRef, useState } from 'react';
import {
  AttachmentOptionsComponent,
  AttachmentOptionsComponentProps,
} from '../../../common/attachment-transforms/attachment-options-component';
import { FormControl } from '../../../common/layout/form';
import { BFButton, BFInput, BFLabel, InputSize } from '@integration-frontends/common/ui';

export type CdnEmbedOptionsProps = Omit<AttachmentOptionsComponentProps, 'placement'>;

export function CdnEmbedOptionsComponent(props: CdnEmbedOptionsProps) {
  const [copied, setCopied] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  function copy() {
    inputRef.current?.select();
    inputRef.current?.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setCopied(true);
  }

  const copiedText = t`Copied!`;
  const copyText = t`Copy`;

  return (
    <FlexSpacer direction={'vertical'} data-testid="cdn-embed-options">
      <AttachmentOptionsComponent {...props} placement={false} />

      <FlexSpacer size={'xs'} style={{ alignItems: 'flex-end' }}>
        <FormControl className="grow">
          <BFLabel>
            <Trans>Attachment CDN Link</Trans>
          </BFLabel>
          <BFInput
            ref={inputRef}
            size={InputSize.Medium}
            type="text"
            data-testid="copy-cdn-link-input"
            value={props.attachment?.url}
            readOnly
          />
        </FormControl>

        <BFButton data-testid="copy-cdn-link-btn" onClick={copy}>
          {copied ? copiedText : copyText}
        </BFButton>
      </FlexSpacer>
    </FlexSpacer>
  );
}
