import { t } from "@lingui/core/macro";
import { call, takeEvery } from 'redux-saga/effects';
import { selectAllAssetsError, selectAllAssetsInit, selectAllAssetsSuccess } from '../actions';
import { searchAssetsSelectors } from '../../../selectors';
import { put, select } from 'typed-redux-saga';
import {
  ASSET_REPO_TOKEN,
  AssetIdResults,
  AssetIdsResultSetBase,
  Brandfolder,
  Collection,
  DEFAULT_SORT_OPTIONS,
  IAssetRepo,
  ListOptions,
  SearchParameters,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { clearToasts, closeToast, createToast } from '@integration-frontends/common/notifications';
import { DEFAULT_SELECTED_ASSETS_LIMIT } from '../reducer';

const handler = function* () {
  const container: Brandfolder | Collection = yield select(searchAssetsSelectors.container);
  const searchParams = yield select(searchAssetsSelectors.searchParams);
  const sortOptions = yield select(searchAssetsSelectors.sortOptions);
  const loadingToastId = `select-all-assets-init-loading-${Date.now()}`;
  const successSelectedToastId = `select-all-assets-init-success-${Date.now()}`;

  yield put(clearToasts());
  try {
    yield put(
      createToast({
        id: loadingToastId,
        type: 'info',
        toastContent: { contentText: t`Selecting all files...` },
      }),
    );
    const response = yield call(fetchContainerAssetIds, searchParams, sortOptions);
    const assetIds = response.data.assetIds;
    const totalAssets = response.recordCount;
    yield put(selectAllAssetsSuccess({ assetIds }));
    yield put(closeToast({ id: loadingToastId }));
    if (totalAssets <= DEFAULT_SELECTED_ASSETS_LIMIT) {
      yield put(
        createToast({
          id: successSelectedToastId,
          type: 'success',
          toastContent: { contentText: t`All files selected.` },
        }),
      );
    }

  } catch (error) {
    yield put(selectAllAssetsError({ error }));
  }

  function fetchContainerAssetIds(
    searchParams: SearchParameters,
    sortOptions: SortOptions,
  ): Promise<AssetIdResults<AssetIdsResultSetBase>> {
    const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
    const options: ListOptions = {
      searchParams,
      sort: sortOptions || DEFAULT_SORT_OPTIONS,
    };
    return assetRepo.listContainerAssetIdsBase(container, options);
  }
};

export function* selectAllAssetsInitEffects() {
  yield takeEvery(selectAllAssetsInit, handler);
}
