import { Trans } from "@lingui/react/macro";

import {
  deselectAllAttachments,
  selectAllAttachmentsInit,
  selectAttachmentSelectors,
} from '@integration-frontends/integration/core/application';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './multi-select-overlay.scss';
import { MultiSelectActionsMenu } from '../multi-select-actions-menu';
import { NotificationsContainer } from '../common/notifications/notifications';

export function MultiSelectOverlay() {
  const dispatch = useDispatch();
  const selectedAttachments = useSelector(selectAttachmentSelectors.selectedAttachments);

  const selectAll = () => {
    dispatch(selectAllAttachmentsInit());
  };

  const deselectAll = () => {
    dispatch(deselectAllAttachments());
  };

  return (
    <section
      className={`multi-select-overlay ${selectedAttachments.length ? 'open' : ''}`}
      id={'multi-select-overlay'}
    >
      <NotificationsContainer placement="above" location="multi-select" bottom={140} />
      <div
        className="flex flex-row items-center justify-between justify-between"
        style={{ width: '97vw' }}
      >
        <div className="flex flex-row items-center">
          <p className="font-medium">Select:</p>
          <p
            onClick={selectAll}
            className="link multi-select-overlay__link cursor-pointer font-bold"
          >
            <Trans>All Visible</Trans>
          </p>
          <p
            onClick={deselectAll}
            className="link multi-select-overlay__link cursor-pointer font-bold"
          >
            <Trans>None</Trans>
          </p>
        </div>
        <div className="flex flex-row items-center">
          <MultiSelectActionsMenu />
        </div>
      </div>
    </section>
  );
}
