/* eslint-disable @nx/enforce-module-boundaries */
import {
  collectionEntityActions,
  searchAssetsActions,
} from '@integration-frontends/integration/core/application';
import {
  Collection,
  ResourceBaseSortableProperty,
  SearchAssetsGroupBy,
  SortDirection,
} from '@integration-frontends/integration/core/model';
import { put, take, takeEvery } from 'typed-redux-saga';
import { smarContentLibrarySearchInit } from '../actions';

const DEFAULT_PAGE_SIZE = 48;
const DEFAULT_SORT_OPTIONS = {
  direction: SortDirection.Desc,
  field: ResourceBaseSortableProperty.CreatedAt,
};

export function* contentLibraryInitSearchEffects() {
  yield takeEvery(
    smarContentLibrarySearchInit,
    function* (action: ReturnType<typeof smarContentLibrarySearchInit>) {
      const { container } = action.payload;
      yield put(collectionEntityActions.collectionsReceived([container as Collection]));
      yield put(searchAssetsActions.init({ container, isFileLibrary: true }));
      yield put(searchAssetsActions.changePageSize({ pageSize: DEFAULT_PAGE_SIZE }));
      yield put(searchAssetsActions.changeGroupBy({ groupBy: SearchAssetsGroupBy.Container }));
      yield take(searchAssetsActions.initSuccess);
      yield put(
        searchAssetsActions.baseSearch({
          searchParams: null,
          sortOptions: DEFAULT_SORT_OPTIONS,
        }),
      );
    },
  );
}
