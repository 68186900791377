import { Trans } from "@lingui/react/macro";
import { Disclosure } from '@headlessui/react';
import {
  brandfolderEntitySelectors,
  collectionEntitySelectors,
  organizationEntitySelectors,
  orgLandingPageEntered,
  selectAttachmentSelectors,
} from '@integration-frontends/integration/core/application';
import { Brandfolder, filterByOrganization } from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrandfolderListItem } from './brandfolder-list-item';
import { OrganizationSectionBanner } from './organization-section-banner';
import './organization-landing-page.scss';
import { IntegrationPageLoader } from '../../../common/page-loader';
import { useAttachmentSelectorNavigation } from '../../navigation';
import { enterToClick } from '@integration-frontends/common/ui';
import {
  PageContainer,
  PageContent,
  PageHeader,
  PageScrollContainer,
} from '../../../common/layout/page';

export function OrganizationLandingPage() {
  const dispatch = useDispatch();
  const loading = useSelector(selectAttachmentSelectors.pageLoading);
  const organizations = useSelector(organizationEntitySelectors.selectAll);
  const multipleOrganizations = organizations.length > 1;
  const brandfolders = useSelector(brandfolderEntitySelectors.selectAll);
  const collections = useSelector(collectionEntitySelectors.selectAll);
  const navigation = useAttachmentSelectorNavigation();

  useEffect(() => {
    dispatch(orgLandingPageEntered());
  }, []);

  function onBrandfolderSelected(brandfolder: Brandfolder) {
    navigation.goToShowPage(brandfolder.id);
  }

  const collectionsForOrganization = useCallback(
    (orgId: string) => {
      return collections.filter(
        (collection) =>
          brandfolders.find((b) => b.id === collection.brandfolderId)?.organizationId === orgId,
      );
    },
    [collections],
  );

  return (
    <PageContainer data-testid="org-landing-page">
      <PageScrollContainer className="h-full">
        {multipleOrganizations ? (
          <PageHeader>
            <h2 className="organizations-list-header">
              <Trans>Organizations</Trans>
            </h2>
          </PageHeader>
        ) : (
          organizations.map((org, index) => (
            <PageHeader>
              <OrganizationSectionBanner
                collapsed={false}
                organization={org}
                brandfolderCount={brandfolders.filter(filterByOrganization(org.id)).length}
                multipleOrganizations={false}
              />
            </PageHeader>
          ))
        )}

        <PageContent
          data-testid="organizations-list"
          className="organizations-list"
          data-cy="organizations-list"
        >
          {loading ? (
            <IntegrationPageLoader fullMinusOrgHeader={true} />
          ) : (
            <ul>
              {organizations.map((org, index) => (
                <li data-testid="org-list-item" className="org-list-item" key={org.id}>
                  <Disclosure defaultOpen={index === 0}>
                    {({ open }) => (
                      <>
                        {multipleOrganizations && (
                          <Disclosure.Button className="w-full">
                            <OrganizationSectionBanner
                              collapsed={!open}
                              organization={org}
                              brandfolderCount={
                                brandfolders.filter(filterByOrganization(org.id)).length
                              }
                              multipleOrganizations={multipleOrganizations}
                            />
                          </Disclosure.Button>
                        )}

                        <Disclosure.Panel>
                          <ul>
                            {brandfolders
                              .filter(filterByOrganization(org.id))
                              .map((brandfolder, index) => (
                                <BrandfolderListItem
                                  key={brandfolder.id}
                                  brandfolder={brandfolder}
                                  brandfolderCount={
                                    brandfolders.filter(filterByOrganization(org.id)).length
                                  }
                                  collections={collectionsForOrganization(org.id).filter(
                                    (collection) => collection.brandfolderId === brandfolder.id,
                                  )}
                                  onKeyDown={enterToClick}
                                  tabIndex={0}
                                  onClick={() => onBrandfolderSelected(brandfolder)}
                                  className={classNames({
                                    'brandfolders-list-item': index < brandfolders.length - 1,
                                  })}
                                />
                              ))}
                          </ul>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </li>
              ))}
            </ul>
          )}
        </PageContent>
      </PageScrollContainer>
    </PageContainer>
  );
}
