import { Trans } from "@lingui/react/macro";
import {
  hasFilters,
  isEnforcedQuery,
  SearchParameters,
  Section,
} from '@integration-frontends/integration/core/model';
import { FiltersDisplayContainer } from '../filters-display';
import { ResultsDisplay } from './results-display';
import { AssetsListContainer } from '../assets-list';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ISelectAttachmentOptions,
  searchAssetsSelectors,
  searchRefresh,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
} from '@integration-frontends/integration/core/application';
import { DI_CONTAINER } from '@integration-frontends/core';

export interface SearchResultsProps {
  containerId: string;
  searchParams: SearchParameters;
  selectedSection?: Section;
  showAttachments: boolean;
}

export function SearchResults({
  containerId,
  searchParams,
  selectedSection,
  showAttachments,
}: SearchResultsProps) {
  const searchError = useSelector(searchAssetsSelectors.error);
  const dispatch = useDispatch();
  const { hiddenSections = [], enforcedSearchParameters }: ISelectAttachmentOptions =
    DI_CONTAINER.get(SELECT_ATTACHMENT_OPTIONS_TOKEN);

  function tryAgain() {
    dispatch(searchRefresh());
  }

  return (
    <div className="gap-xl pb-lg flex flex-col">
      {searchError ? (
        <div data-testid={'search-error'} className="flex flex-col">
          <span className="text-center" role="alert" aria-label="error">
            <Trans>There was an error while searching.</Trans>
          </span>
          <span
            data-testid="try-again-btn"
            className="link text-center"
            role="button"
            onClick={tryAgain}
          >
            <Trans>Try again</Trans>
          </span>
        </div>
      ) : (
        <>
          {hasFilters(searchParams) && !isEnforcedQuery(searchParams, enforcedSearchParameters) && (
            <>
              <FiltersDisplayContainer searchParams={searchParams} />
              <ResultsDisplay containerId={containerId} />
            </>
          )}
          <AssetsListContainer
            hiddenSections={hiddenSections}
            containerId={containerId}
            selectedSection={selectedSection}
            showAttachments={showAttachments}
          />
        </>
      )}
    </div>
  );
}
